<!-- eslint-disable max-len -->
<template>
  <div class="flex flex-row">
    <div class="flex items-center justify-center w-full">
      <BaseCard class="w-full mb-6">
        <div class="lg:mx-0 mx-10">
          <div class="mb-2 mt-6 font-bold">
            Beitragskompensationen für behördliche Schließungen während des Lock Downs in Deiner Stadt
          </div>
          <div class="mb-2">
            Gewählte Option betrifft Schließung(en) ab Dezember 2021 und behält ihre Gültigkeit auch für mögliche weitere Schließungsszenarien im Winter 2021/22.
          </div>
          <div v-if="!compensationAlreadySet" class="mb-2 font-bold">
            Solltest Du keine Option registrieren, hängen wir Dir die Zeit der Schließung einfach beitragsfrei nach Ende Deiner Mitgliedschaft an. Dies ist in
  deinem Mitgliedskonto hinterlegt, so dass Deine Member Card Ihre Gültigkeit behält.
          </div>
          <CheckBox v-if="!compensationAlreadySet" class="mt-4" v-model="acceptCompensationConditions">
                {{ $t('acceptCompensationConditions') }}
          </CheckBox>
          <!-- compensationType PAUSE_VVL -->
          <div v-if="!compensationAlreadySet || compensationType === 'PAUSE_VVL'">
          <Button
            v-if="!compensationAlreadySet"
            @click="showCompensationContinueModal('PAUSE_VVL')"
            :disabled="compensationAlreadySet || !acceptCompensationConditions"
            type="submit"
            class="w-full my-4"
            primary
          >
            {{ $t("PAUSE_VVL") }}
          </Button>
          <div v-else-if="compensationType === 'PAUSE_VVL'" class="text-lg  font-bold mb-2">
            <div class="text-primary-500">Aktivierte Option: </div>
            <div class="underline">{{ $t("PAUSE_VVL") }}</div>
          </div>
          <div class="mb-2 font-bold">
            Mit Beantragung der Beitragspause / Guthabenaktivierung stoppe ich ab sofort weitere Beitragszahlungen während der behördlichen Schließung.
          </div>
          <ul class="list-disc">
            <li>
              Die Laufzeit Deiner Mitgliedschaft wird unterbrochen.
            </li>
            <li>
              Keine Beitragszahlungen während der Schließung.
            </li>
            <li>
              Vertragsanpassung (kostenpflichtig) um den Zeitraum der Schließung (bereits gezahlte Beiträge während der Schließung werden angerechnet).
            </li>
          </ul>
          <div class="my-2">
            Somit garantieren wir Dir die ursprünglich vereinbarte Laufzeit, Dir entsteht kein Nachteil!
          </div>
        </div>
        <!-- compensationType KRISENHELD -->
        <div v-if="!compensationAlreadySet || compensationType === 'KRISENHELD'">
          <Button
            v-if="!compensationAlreadySet"
            @click="showCompensationContinueModal('KRISENHELD')"
            :disabled="compensationAlreadySet || !acceptCompensationConditions"
            type="submit"
            class="w-full my-4"
            primary
          >
            {{ $t("KRISENHELD") }}
          </Button>
          <div v-else-if="compensationType === 'KRISENHELD'" class="text-lg  font-bold mb-2">
            <div class="text-primary-500">Aktivierte Option: </div>
            <div class="underline">{{ $t("KRISENHELD") }}</div>
          </div>
          <div class="mb-2 font-bold">
          Ich möchte FIT STAR mit meinen Beitragszahlungen unterstützen.
        </div>
        <ul class="list-disc">
          <li>
            Beitragszahlung läuft während der Schließung weiter.
          </li>
          <li>
            Keine Vertragsanpassung.
          </li>
          <li>
            Ich verzichte auf weitere Kompensationsmaßnahmen für die behördlich angeordnete Schließung.
          </li>
        </ul>
        <div class="my-2">
          Wow – Du bist großartig! Unsere Mitarbeiter bedanken sich für Deinen Support!
        </div>
      </div>
        <!-- compensationType 'GUTSCHEIN_150' -->
        <div v-if="!compensationAlreadySet || compensationType === 'GUTSCHEIN_150'">
          <Button
            v-if="!compensationAlreadySet"
            @click="showCompensationContinueModal('GUTSCHEIN_150')"
            :disabled="compensationAlreadySet || !acceptCompensationConditions"
            type="submit"
            class="w-full my-4"
            primary
          >
            {{ $t("GUTSCHEIN_150") }}
          </Button>
          <div v-else-if="compensationType === 'GUTSCHEIN_150'" class="text-lg  font-bold mb-2">
            <div class="text-primary-500">Aktivierte Option: </div>
            <div class="underline">{{ $t("GUTSCHEIN_150") }}</div>
          </div>
          <div class="mb-2 font-bold">
            Buddy-Building. Gratis Training für eine/n Freund/in.
          </div>
          <ul class="list-disc mb-6">
            <li>
              Beitragszahlung läuft während der Schließung weiter.
            </li>
            <li>
              Keine Vertragsanpassung.
            </li>
            <li>
              kostenlose Mitgliedschaft über die 1,5-fache Schließungszeit für einen Freund/in (Nicht-Mitglied).
            </li>
            <li>
              Eine Auszahlung ist nicht möglich.
            </li>
            <li>
              Ich verzichte auf weitere Kompensationsmaßnahmen für die behördlich angeordnete Schließung.
            </li>
          </ul>
        </div>
        </div>
      </BaseCard>
    </div>
    <DialogModal
          alert
          v-model="modalState.compensationFailed"
          :primary-text="$t('attention')"
          :secondary-text="$t('compensationFailed')"
          @approve="toLogin"
      />
      <DialogModal
          alert
          v-model="modalState.compensationSuccess"
          :primary-text="$t('information')"
          :secondary-text="$t('compensationSuccess')"
          @approve="onSuccess"
      />
      <DialogModal
          html
          v-model="compensationContinueModal"
          primary-text="Beitragskompensation registrieren"
          :secondary-text="`Option <b>\'${$t(this.selectedCompensationType)}\'</b> bestätigen`"
          @cancel="compensationContinueModal=false"
          @approve="setCompensation"
      />
  </div>
</template>

<script>

import BaseCard from './components/BaseCard.vue';
import DialogModal from '../../components/DialogModal.vue';

export default {
  name: 'Compensation',
  components: { BaseCard, DialogModal },
  data() {
    return {
      compensationContinueModal: false,
      acceptCompensationConditions: false,
      selectedCompensationType: '',
    };
  },
  computed: {
    hasFloatingWidth() {
      return window.innerWidth < 1024;
    },
    modalState: {
      get() {
        return this.$store.state.memberArea.modalState;
      },
      set(value) {
        this.$store.commit('setModalState', value);
      },
    },
    compensationAlreadySet() {
      return this.compensationType !== 'NONE';
    },
    compensationType() {
      return this.$store.state.memberArea.compensationType;
    },
  },
  mounted() {
    this.$store.dispatch('getLockdownCompensation');
  },
  methods: {
    toLogin() {
      this.modalState.compensationFailed = false;
      this.modalState.compensationSuccess = false;
      this.$store.dispatch('logout');
      this.$router.push({ name: 'member-login' });
    },
    onSuccess() {
      this.modalState.compensationSuccess = false;
      this.$store.dispatch('getLockdownCompensation');
    },
    setCompensation() {
      this.compensationContinueModal = false;
      this.setCompensationType(this.selectedCompensationType);
      this.$store.dispatch('setLockdownCompensation');
    },
    setCompensationType(value) {
      this.$store.commit('setCompensationType', value);
    },
    showCompensationContinueModal(value) {
      console.log('showCompensationContinueModal value: ', value);
      this.selectedCompensationType = value;
      this.compensationContinueModal = true;
    },
  },
};
</script>

<style scoped>
.content-height {
  min-height: 120vh;
}
</style>
